export type QueryExpression = any;

export interface SetPageOptions {
  // Based on Mat Table's interface
  pageIndex?: number;
  pageSize?: number;
  //
  page?: number;
  pageLimit?: number;
}

export enum PaginationEvents {
  refreshStart,
  refreshCancelled,
  refreshEnd,
}

export type ParamValue = string | number | boolean | Date;

