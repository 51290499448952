<header>
  <main class="height" [ngClass]="{scrolled:isScrolled|async}"
        fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px"
        fxLayoutGap.gt-sm="46px">
    <bb-branding></bb-branding>
    <bb-nav-desktop fxFlex="auto" fxHide [fxHide.gt-xs]="false"></bb-nav-desktop>
    <bb-nav-button fxFlex="auto" fxShow [fxShow.gt-xs]="false"></bb-nav-button>
  </main>
</header>
<div class="height" [ngClass]="{scrolled:isScrolled|async}"></div>
