var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import 'rxjs-compat/add/operator/skip';
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(title, router) {
        var _this = this;
        this.title = title;
        this.router = router;
        this.router.events
            .filter(function (e) { return e instanceof NavigationEnd; })
            .skip(1)
            .subscribe(function (e) { return _this.dataLayer({
            virtualPageURL: e.url,
            virtualtrigger: 'website_navigated_ok',
        }); });
    }
    AnalyticsService.prototype.dataLayer = function (data) {
        var finalData = __assign({ virtualPageTitle: this.title.getTitle() || '-', virtualPageURL: location.pathname, event: 'VirtualPageview', virtualtrigger: 'push_state_navigation_ok' }, data);
        if (window.dataLayer) {
            dataLayer.push(finalData);
        }
        else {
            console.log('dataLayer', finalData);
        }
    };
    return AnalyticsService;
}());
export { AnalyticsService };
