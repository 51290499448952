<section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
  <nav fxFlex="auto" fxLayout="row wrap" fxLayoutGap="16px"
       fxLayoutGap.gt-sm="32px">
    <a routerLink="/how-it-works">How it works</a>
    <a routerLink="/faq">FAQ</a>
    <a routerLink="/contact-us">Contact Us</a>
    <a href="/blog">Blog</a>
  </nav>
  <a routerLink="/apply-now" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
    <div class="fa-stack">
      <i class="fa fa-circle-thin fa-stack-2x"></i>
      <i class="fa fa-check fa-stack-1x"></i>
    </div>
    <div>Apply Now</div>
  </a>
</section>
