import {AfterViewInit, Component, HostListener, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {WebsiteStatesService} from './website-states.service';

@Component({
  selector: 'bb-root',
  template: '<router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>',
})
export class AppComponent implements AfterViewInit {
  constructor(private website: WebsiteStatesService,
              @Inject(DOCUMENT) private document: Document) {
  }

  onActivate(event, outlet) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  ngAfterViewInit() {
    this.website.windowResize.next(window);
  }

  @HostListener('window:resize')
  onResize() {
    this.website.windowResize.next(window);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.website.windowScroll.next(this.document);
  }
}
