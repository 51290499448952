import {BrowserModule, Title} from '@angular/platform-browser';
import {ErrorHandler, Injectable, Injector, NgModule, Provider} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './@body/main/main.component';
import {HeaderComponent} from './@body/header/header.component';
import {FooterComponent} from './@body/footer/footer.component';
import {BodyComponent} from './@body/body/body.component';
import {BrandingComponent} from './@body/branding/branding.component';
import {NavMobileComponent} from './@body/nav-mobile/nav-mobile.component';
import {NavDesktopComponent} from './@body/nav-desktop/nav-desktop.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatSidenavModule, MatSnackBar, MatSnackBarModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavButtonComponent} from './@body/nav-button/nav-button.component';
import {HttpClientModule} from '@angular/common/http';
import {BranchDBService} from './@db/branch-db.service';
import {BBRestEndpointService} from './@modules/bb-rest/bb-rest-endpoint.service';
import {OverlayModule} from '@angular/cdk/overlay';
import {WebsiteStatesService} from './website-states.service';
import {AnalyticsService} from './analytics.service';
import {ContactUsDBService} from './@db/contact-us-db.service';
import * as Raven from 'raven-js';
import {PersistenceModule} from 'angular-persistence';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    if (error instanceof Error) {
      console.error(error);
    } else {
      console.error(JSON.stringify(error));
    }
    // noinspection TypeScriptUnresolvedVariable
    Raven.captureException(error.originalError || error);

    // Notify User
    const snackBar: MatSnackBar = this.injector.get(MatSnackBar);
    snackBar.open(`😨 Error: ${Raven.lastEventId()} 😱`, 'Okay', {verticalPosition: 'top'});
  }
}

let errorService: Provider = ErrorHandler;

if (location.hostname === 'www.balikbayad.ph') {
  errorService = {provide: ErrorHandler, useClass: RavenErrorHandler};
}


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    BrandingComponent,
    NavMobileComponent,
    NavDesktopComponent,
    NavButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexModule,
    FlexLayoutModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverlayModule,
    MatSnackBarModule,
    PersistenceModule
  ],
  providers: [
    Title,
    BranchDBService,
    ContactUsDBService,
    BBRestEndpointService,
    WebsiteStatesService,
    AnalyticsService,
    errorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private analytics: AnalyticsService) {
  }
}
