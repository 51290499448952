<mat-sidenav-container>
  <mat-sidenav mode="over"
               [opened]="open"
               (closedStart)="open = false"
               position="end"
               (click)="sidenav.toggle()"
               #sidenav
               [fixedInViewport]="true">
    <bb-nav-mobile></bb-nav-mobile>
  </mat-sidenav>
  <mat-sidenav-content>
    <section fxLayout="column">
      <bb-header></bb-header>
      <bb-body fxFlex="auto"></bb-body>
      <bb-footer></bb-footer>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
