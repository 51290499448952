import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './@body/main/main.component';

const routes: Routes = [
  {
    path: '', component: MainComponent, children: [
      {path: '', loadChildren: './home/home.module#HomeModule'},
      {path: 'faq', loadChildren: './faq/faq.module#FAQModule'},
      {path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsModule'},
      {path: 'about-us', loadChildren: './@contents/about-us/about-us.module#AboutUsModule'},
      {path: 'resources', loadChildren: './@contents/resources/resources.module#ResourcesModule'},
      {path: 'how-it-works', loadChildren: './@contents/how-it-works/how-it-works.module#HowItWorksModule'},
      {path: 'why-balikbayad', loadChildren: './@contents/why-balikbayad/why-balikbayad.module#WhyBalikbayadModule'},
      {path: 'getting-a-loan', loadChildren: './@contents/getting-a-loan/getting-a-loan.module#GettingALoanModule'},
      {path: 'terms-and-conditions', loadChildren: './@contents/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsModule'},
      {path: 'privacy-statement', loadChildren: './@contents/privacy-statement/privacy-statement.module#PrivacyStatementModule'},
      {path: 'responsible-lending', loadChildren: './@contents/responsible-lending/responsible-lending.module#ResponsibleLendingModule'},
      {path: '**', loadChildren: './@contents/not-found/not-found.module#NotFoundModule'},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
