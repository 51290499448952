var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isArray, isNull, isObject, isString } from 'util';
import { BBRestPagination } from './bb-rest-pagination.service';
var BBRestEndpointService = /** @class */ (function () {
    function BBRestEndpointService(http) {
        this.http = http;
    }
    BBRestEndpointService.prototype.getAPIPrefix = function () {
        return '/api';
    };
    BBRestEndpointService.prototype.run_request = function (verb, relative_postfix, body, params, extraParams) {
        var finalParams = {};
        var finalUrl = this.getAPIPrefix() + '/' + relative_postfix;
        var finalOptions = { params: finalParams };
        if (!params) {
            params = {};
        }
        if (params.nested) {
            finalUrl = this.getAPIPrefix() + '/' + params.nested.join('/') + '/' + relative_postfix;
        }
        if (body) {
            finalOptions['body'] = body;
        }
        if (params.query) {
            var queryStr = params.query;
            if (!isString(queryStr)) {
                queryStr = JSON.stringify(queryStr, function (key, value) {
                    if (!isNull(value)) {
                        return value;
                    }
                });
            }
            finalParams.query = queryStr;
        }
        if (params.count) {
            finalParams.count = params.count ? '1' : '0';
        }
        else if (params.exists) {
            finalParams.exists = params.exists ? '1' : '0';
        }
        else if (params.first) {
            finalParams.first = params.first ? '1' : '0';
        }
        else if (params.last) {
            finalParams.last = params.last ? '1' : '0';
        }
        else if (params.get) {
            finalParams.get = params.get ? '1' : '0';
        }
        else if (params.pksOnly) {
            finalParams.pk = params.pksOnly ? '1' : '0';
        }
        else {
            // Otherwise, this is
            if (params.pageSize) {
                finalParams.limit = '' + params.pageSize;
            }
            if (params.orderBy) {
                var orderBy = params.orderBy;
                if (isObject(params) && isArray(params)) {
                    orderBy = orderBy.join(',');
                }
                finalParams.orderBy = '' + orderBy;
            }
            if (params.pageIndex) {
                finalParams.page = '' + params.pageIndex;
            }
        }
        if (params.orderBy) {
            var orderBy = params.orderBy;
            if (isObject(params) && isArray(params)) {
                orderBy = orderBy.join(',');
            }
            finalParams.orderBy = '' + orderBy;
        }
        if (extraParams) {
            Object.assign(finalParams, extraParams);
        }
        return this.http.request(verb, finalUrl, finalOptions);
    };
    BBRestEndpointService.prototype.run_list = function (verb, resource_prefix, body, params, extraParams) {
        return this.run_request(verb, resource_prefix, body, params, extraParams);
    };
    BBRestEndpointService.prototype.run_detail = function (verb, resource_prefix, pk, body, params, extraParams) {
        var relative_postfix = resource_prefix + '/' + pk;
        return this.run_request(verb, relative_postfix, body, params, extraParams);
    };
    BBRestEndpointService.prototype.run_list_method = function (verb, resource_prefix, method, body, params, extraParams) {
        var relative_postfix = resource_prefix + '/' + method;
        return this.run_request(verb, relative_postfix, body, params, extraParams);
    };
    BBRestEndpointService.prototype.run_detail_method = function (verb, resource_prefix, pk, method, body, params, extraParams) {
        var relative_postfix = resource_prefix + '/' + pk + '/' + method;
        return this.run_request(verb, relative_postfix, body, params, extraParams);
    };
    BBRestEndpointService.prototype.getListResult = function (resource_prefix, query, page, ordering, limit, extra_params) {
        if (query === void 0) { query = {}; }
        if (page === void 0) { page = 1; }
        if (ordering === void 0) { ordering = null; }
        if (limit === void 0) { limit = 100; }
        var params = {
            pageIndex: page,
            orderBy: ordering,
            pageSize: limit,
        };
        if (query) {
            params.query = JSON.stringify(query);
        }
        return this.run_list('GET', resource_prefix, null, params, extra_params);
    };
    BBRestEndpointService.prototype.getAllRecords = function (resource_prefix, query, ordering, extra_params, pageLimit) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (ordering === void 0) { ordering = null; }
        if (pageLimit === void 0) { pageLimit = 1000; }
        return Observable.create(function (observer) { return __awaiter(_this, void 0, void 0, function () {
            var pageNumber, results, _i, _a, record;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        pageNumber = 1;
                        _b.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getListResult(resource_prefix, query, pageNumber, ordering, pageLimit, extra_params).toPromise()];
                    case 2:
                        results = _b.sent();
                        for (_i = 0, _a = results.results; _i < _a.length; _i++) {
                            record = _a[_i];
                            observer.next(record);
                        }
                        if (!results.next) {
                            return [3 /*break*/, 3];
                        }
                        pageNumber++;
                        return [3 /*break*/, 1];
                    case 3:
                        observer.complete();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    BBRestEndpointService.prototype.getPagination = function (resource_prefix, pageLimit) {
        var pagination = new BBRestPagination(this);
        if (resource_prefix) {
            pagination.prefix = resource_prefix;
        }
        if (pageLimit) {
            pagination.pageLimit = pageLimit;
        }
        return pagination;
    };
    BBRestEndpointService.prototype.getLimitedRecords = function (resource_prefix, query, ordering, extra_params, resultsLimit) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (ordering === void 0) { ordering = null; }
        if (resultsLimit === void 0) { resultsLimit = 1000; }
        return Observable.create(function (observer) { return __awaiter(_this, void 0, void 0, function () {
            var pageLimit, pageNumber, resultsCount, results, _i, _a, record;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        pageLimit = Math.min(resultsLimit, 1000);
                        pageNumber = 1;
                        resultsCount = 0;
                        _b.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getListResult(resource_prefix, query, pageNumber, ordering, pageLimit, extra_params).toPromise()];
                    case 2:
                        results = _b.sent();
                        for (_i = 0, _a = results.results; _i < _a.length; _i++) {
                            record = _a[_i];
                            observer.next(record);
                            resultsCount++;
                            if (resultsCount >= resultsLimit) {
                                break;
                            }
                        }
                        if (!results.next) {
                            return [3 /*break*/, 3];
                        }
                        if (resultsCount >= resultsLimit) {
                            return [3 /*break*/, 3];
                        }
                        pageNumber++;
                        return [3 /*break*/, 1];
                    case 3:
                        observer.complete();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return BBRestEndpointService;
}());
export { BBRestEndpointService };
