import {enableProdMode, ErrorHandler} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule, RavenErrorHandler} from './app/app.module';
import {environment} from './environments/environment';
import * as Raven from 'raven-js';

import 'rxjs-compat/add/operator/debounceTime';
import 'rxjs-compat/add/operator/shareReplay';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/operator/mergeMap';
import 'rxjs-compat/add/operator/filter';
import 'rxjs-compat/add/operator/toArray';
import 'rxjs-compat/add/operator/first';
import 'rxjs-compat/add/operator/retryWhen';
import 'rxjs-compat/add/operator/finally';
import 'rxjs-compat/add/operator/do';
import 'rxjs-compat/add/operator/take';
import 'rxjs-compat/add/operator/last';
import 'rxjs-compat/add/operator/defaultIfEmpty';
import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/shareReplay';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/concatMap';
import 'rxjs-compat/add/operator/throttleTime';
import 'rxjs-compat/add/operator/scan';
import 'rxjs-compat/add/operator/combineLatest';
import 'rxjs-compat/add/operator/catch';
import 'rxjs-compat/add/operator/delay';
import 'rxjs-compat/add/observable/interval';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/observable/combineLatest';
import 'rxjs-compat/add/observable/merge';
import 'rxjs-compat/add/observable/timer';
import 'rxjs-compat/add/observable/range';
import 'rxjs-compat/add/observable/throw';

declare const appVersion: string;

if (environment.production) {
  enableProdMode();
}

if (location.hostname === 'www.balikbayad.ph') {
  Raven
    .config('https://fa30db80981f48c2ac692fca956427ab@sentry.io/1211146', {
      release: appVersion,
      ignoreUrls: [
        /.*tawk.to\/.*/,
        /.*inspectlet.com\/.*/,
      ],
    }).install();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
