import {Component} from '@angular/core';

@Component({
  selector: 'bb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  open = false;
}
