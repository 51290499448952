import {Component} from '@angular/core';
import {WebsiteStatesService} from '../../website-states.service';
import {ScrollState} from '../../website-states.enum';

@Component({
  selector: 'bb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isScrolled = this.website.scrollState.map((s: ScrollState) => s.topOffset > 85);

  constructor(private website: WebsiteStatesService) {
  }
}
