/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./nav-mobile.component";
var styles_NavMobileComponent = [i0.styles];
var RenderType_NavMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavMobileComponent, data: {} });
export { RenderType_NavMobileComponent as RenderType_NavMobileComponent };
export function View_NavMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "nav", [["fxLayout", "column"], ["fxLayoutGap", "16px"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 1785856, null, 0, i2.LayoutGapDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i1.NgZone, i4.Directionality, i3.StyleUtils], { gap: [0, "gap"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["routerLink", "/how-it-works"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["How it works"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["routerLink", "/faq"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["FAQ"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["routerLink", "/contact-us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "/blog"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Blog"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "hr", [["fxFill", ""]], null, null, null, null, null)), i1.ɵdid(15, 737280, null, 0, i2.FlexFillDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [["class", "apply-now"], ["routerLink", "/apply-now"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "span", [["class", "fa-stack"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "fa fa-circle-thin fa-stack-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa fa-check fa-stack-1x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Apply Now "]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "16px"; _ck(_v, 2, 0, currVal_1); var currVal_4 = "/how-it-works"; _ck(_v, 4, 0, currVal_4); var currVal_7 = "/faq"; _ck(_v, 7, 0, currVal_7); var currVal_10 = "/contact-us"; _ck(_v, 10, 0, currVal_10); _ck(_v, 15, 0); var currVal_13 = "/apply-now"; _ck(_v, 17, 0, currVal_13); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 7).target; var currVal_6 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 10).target; var currVal_9 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_11 = i1.ɵnov(_v, 17).target; var currVal_12 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_11, currVal_12); }); }
export function View_NavMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bb-nav-mobile", [], null, null, null, View_NavMobileComponent_0, RenderType_NavMobileComponent)), i1.ɵdid(1, 49152, null, 0, i7.NavMobileComponent, [], null, null)], null, null); }
var NavMobileComponentNgFactory = i1.ɵccf("bb-nav-mobile", i7.NavMobileComponent, View_NavMobileComponent_Host_0, {}, {}, []);
export { NavMobileComponentNgFactory as NavMobileComponentNgFactory };
