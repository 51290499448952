import {Component} from '@angular/core';
import {MainComponent} from '../main/main.component';

@Component({
  selector: 'bb-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent {
  constructor(public main: MainComponent) {
  }
}
