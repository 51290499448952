import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {BBRestEndpointService} from '../@modules/bb-rest/bb-rest-endpoint.service';
import {ContactUs} from '../@models/contact-us.model';

@Injectable({
  providedIn: 'root',
})
export class ContactUsDBService {
  prefix = 'contact-us';

  constructor(private api: BBRestEndpointService) {
  }

  sendMessage(data): Observable<ContactUs.Model> {
    return this.api.run_list('POST', this.prefix, data);
  }
}
