import {Injectable} from '@angular/core';
import {BBRestEndpointService} from '../@modules/bb-rest/bb-rest-endpoint.service';
import {Observable} from 'rxjs/Observable';
import {Branch} from '../@models/branch.model';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class BranchDBService {
  prefix = 'branches';
  list = this.api.getAllRecords<Branch.Model>(this.prefix).shareReplay();

  constructor(private api: BBRestEndpointService) {
  }

  get(pk: number): Observable<Branch.Model> {
    return this.api.run_detail('GET', this.prefix, pk).catch((error: HttpErrorResponse) => {
      if (error.status === 404) {
        return Observable.of(null);
      } else {
        return Observable.throwError(error);
      }
    });
  }

  getFromAlias(alias: string): Observable<Branch.Model | null> {
    return this.list.filter(b => b.alias === alias).defaultIfEmpty(null).first();
  }

  getDefaultForMap(): Observable<Branch.Model> {
    return this.api.run_list_method('GET', this.prefix, 'default-google-map');
  }
}
