import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DataLayer} from './analytics.model';
import {NavigationEnd, Router} from '@angular/router';
import 'rxjs-compat/add/operator/skip';

declare const dataLayer: DataLayer[];

@Injectable()
export class AnalyticsService {
  constructor(private title: Title,
              private router: Router) {

    this.router.events
      .filter(e => e instanceof NavigationEnd)
      .skip(1)
      .subscribe((e: NavigationEnd) => this.dataLayer({
        virtualPageURL: e.url,
        virtualtrigger: 'website_navigated_ok',
      }));

  }

  dataLayer(data: DataLayer) {
    const finalData = <DataLayer>{
      virtualPageTitle: this.title.getTitle() || '-',
      virtualPageURL: location.pathname,
      event: 'VirtualPageview',
      virtualtrigger: 'push_state_navigation_ok',
      ...data,
    };
    if ((<any>window).dataLayer) {
      dataLayer.push(finalData);
    } else {
      console.log('dataLayer', finalData);
    }
  }
}
