<section>
  <footer fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center"
          fxLayout.gt-sm="row">
    <div>
      <a href="https://www.facebook.com/balikbayadph" class="facebook fa-stack fa-lg">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-facebook fa-stack-1x fa-inverse"></i>
      </a>
    </div>
    <nav fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="center start">
      <a routerLink="/about-us">About Us</a>
      <a href="/blog">Blog</a>
      <a routerLink="/resources">Resources</a>
      <a routerLink="/terms-and-conditions">Terms &amp; Conditions</a>
      <a routerLink="/privacy-statement">Privacy</a>
      <a routerLink="/responsible-lending">Responsible Lending</a>
      <a routerLink="/ofw-loan">OFW Loan</a>
      <a routerLink="/seaman-loan">Seaman Loan</a>
    </nav>
  </footer>
</section>
