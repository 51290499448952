import { BBRestEndpointService } from '../@modules/bb-rest/bb-rest-endpoint.service';
import * as i0 from "@angular/core";
import * as i1 from "../@modules/bb-rest/bb-rest-endpoint.service";
var ContactUsDBService = /** @class */ (function () {
    function ContactUsDBService(api) {
        this.api = api;
        this.prefix = 'contact-us';
    }
    ContactUsDBService.prototype.sendMessage = function (data) {
        return this.api.run_list('POST', this.prefix, data);
    };
    ContactUsDBService.ngInjectableDef = i0.defineInjectable({ factory: function ContactUsDBService_Factory() { return new ContactUsDBService(i0.inject(i1.BBRestEndpointService)); }, token: ContactUsDBService, providedIn: "root" });
    return ContactUsDBService;
}());
export { ContactUsDBService };
