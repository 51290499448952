var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs/Observable';
import { Orientation } from './website-states.enum';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
var WebsiteStatesService = /** @class */ (function () {
    function WebsiteStatesService(breakpoint, document, titleService, meta) {
        var _this = this;
        this.breakpoint = breakpoint;
        this.document = document;
        this.titleService = titleService;
        this.meta = meta;
        this.viewport = Observable
            .merge(this.breakpoint.observe([Breakpoints.XSmall]).filter(function (m) { return m.matches; })
            .map(function () { return ({ gteXSmall: true, gteSmall: true, gteMedium: true }); }), this.breakpoint.observe([Breakpoints.Small]).filter(function (m) { return m.matches; })
            .map(function () { return ({ gteSmall: true, gteMedium: true }); }), this.breakpoint.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).filter(function (m) { return m.matches; })
            .map(function () { return ({ gteMedium: true }); }))
            .switchMap(function (config) { return Observable
            .merge(_this.breakpoint
            .observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
            .filter(function (m) { return m.matches; })
            .map(function () { return (__assign({}, config, { gtePortraitXSmall: config.gteXSmall, gtePortraitSmall: config.gteSmall, gtePortraitMedium: config.gteMedium })); }), _this.breakpoint
            .observe([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape])
            .filter(function (m) { return m.matches; })
            .map(function () { return (__assign({}, config, { gteLandscapeXSmall: config.gteXSmall, gteLandscapeSmall: config.gteSmall, gteLandscapeMedium: config.gteMedium })); })); })
            .shareReplay(1);
        this.windowResize = new ReplaySubject(1);
        this.windowDimensions = this.windowResize
            .map(function ($el) { return ({ height: $el.innerHeight, width: $el.innerWidth }); })
            .startWith({ height: 0, width: 0 });
        this.windowHeight = this.windowDimensions.map(function (d) { return d.height; });
        this.windowWidth = this.windowDimensions.map(function (d) { return d.width - 24 * 2; });
        this.windowDimension = Observable.combineLatest(this.windowHeight, this.windowHeight)
            .map(function (_a) {
            var w = _a[0], h = _a[1];
            return ({ width: w, height: h });
        });
        this.windowOrientation = Observable.combineLatest(this.windowHeight, this.windowHeight)
            .map(function (_a) {
            var w = _a[0], h = _a[1];
            return w > h ? Orientation.portrait : Orientation.landscape;
        });
        this.windowScroll = new BehaviorSubject(this.document);
        this.scrollState = this.windowScroll
            .map(function (d) { return d.documentElement; })
            .combineLatest(this.windowHeight)
            .map(function (_a) {
            var body = _a[0], windowHeight = _a[1];
            return ({
                viewableHeight: windowHeight,
                scrollableHeight: body.scrollHeight,
                topOffset: body.scrollTop,
                bottomOffset: body.scrollHeight - body.scrollTop - windowHeight,
            });
        })
            .shareReplay(1);
        this.title = new BehaviorSubject(null);
        this.finalTitle = this.title.map(function (explicit) { return explicit || 'Balikbayad - OFW and Seaman Loans Made Easy'; });
        this.description = new BehaviorSubject(null);
        this.finalDescription = this.description
            .map(function (description) { return description || 'Our OFW and Seaman Loans come with NO deductions from approved loan amount and NO hidden fees'; });
        this.finalTitle.subscribe(function (title_) { return _this.titleService.setTitle(title_); });
        this.finalDescription.subscribe(function (description) { return _this.meta.updateTag({ name: 'description', content: description }); });
    }
    WebsiteStatesService.prototype.scrollToTop = function () {
        this.document.body.scrollTop = this.document.documentElement.scrollTop = 0;
    };
    return WebsiteStatesService;
}());
export { WebsiteStatesService };
