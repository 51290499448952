/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./nav-button.component";
import * as i5 from "../main/main.component";
var styles_NavButtonComponent = [i0.styles];
var RenderType_NavButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavButtonComponent, data: {} });
export { RenderType_NavButtonComponent as RenderType_NavButtonComponent };
export function View_NavButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["fxLayoutAlign", "end center"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [8, null], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.main.open = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-bars fa-2x menu"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "end center"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NavButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bb-nav-button", [], null, null, null, View_NavButtonComponent_0, RenderType_NavButtonComponent)), i1.ɵdid(1, 49152, null, 0, i4.NavButtonComponent, [i5.MainComponent], null, null)], null, null); }
var NavButtonComponentNgFactory = i1.ɵccf("bb-nav-button", i4.NavButtonComponent, View_NavButtonComponent_Host_0, {}, {}, []);
export { NavButtonComponentNgFactory as NavButtonComponentNgFactory };
