<nav fxLayout="column" fxLayoutGap="16px">
  <a routerLink="/how-it-works">How it works</a>
  <a routerLink="/faq">FAQ</a>
  <a routerLink="/contact-us">Contact Us</a>
  <a href="/blog">Blog</a>
  <hr fxFill>
  <a class="apply-now" routerLink="/apply-now">
    <span class="fa-stack">
      <i class="fa fa-circle-thin fa-stack-2x"></i>
      <i class="fa fa-check fa-stack-1x"></i>
    </span>
    Apply Now
  </a>
</nav>
